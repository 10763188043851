<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-12">
                <strong>Permission Management</strong>
                <a-button
                  class="btn btn-outline-primary ml-2"
                  @click="saveChange"
                  >Save Changes</a-button
                >
                <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
                  v-model="selectedidbpr"
                  style="width: 150px;"
                  placeholder="Select Kode BPR"
                  class="ml-2"
                  @change="resetAll"
                >
                  <a-select-option
                    v-for="(data, index) in databpr"
                    :key="index"
                    :value="data.id_bpr"
                    >{{ data.id_bpr }}</a-select-option
                  >
                </a-select>
                <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
                  v-if="selectedidbpr !== null"
                  v-model="selectedidapp"
                  style="width: 150px;"
                  placeholder="Select App"
                  class="ml-2"
                  @change="checkingIfNotNull"
                >
                  <a-select-option
                    v-for="(data, index) in dataapp"
                    :key="index"
                    :value="data.id"
                    >{{ data.appName }}</a-select-option
                  >
                </a-select>
                <a-select
            show-search
            option-filter-prop="children"
            :filter-option="filterOption"
                  v-if="selectedidbpr !== null && selectedidapp !== null"
                  v-model="role"
                  style="width: 150px;"
                  placeholder="Select Role"
                  class="ml-2"
                  @change="getnamerole"
                >
                  <a-select-option
                    v-for="data in datarole"
                    :key="data.id"
                    :value="data.id"
                    >{{ data.title }}</a-select-option
                  >
                </a-select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <!-- :columns="columns" -->
                <a-table
                  style="
                    margin-left: -26px;
                    margin-right: -26px;
                    margin-top: -21px;
                  "
                  :dataSource="datatable"
                  :expandIconAsCell="true"
                  :expandIconColumnIndex="0"
                  size="small"
                  rowKey="id_Permission"
                  :pagination="{
                    defaultPageSize: 50,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showSizeChanger: true,
                    showTotal: (total) => `Total ${total} items`,
                    pageSizeOptions: ['100', '150', '200'],
                  }"
                >
                  <!-- <template
                    slot="expandedRowRender"
                    slot-scope="record, index"
                    style="margin: 0;"
                  >
                    <a-table
                      v-if="record.children.length !== 0"
                      :bordered="true"
                      :data-source="record.children"
                      size="small"
                      :pagination="{
                        hideOnSinglePage: true,
                        defaultPageSize: 10,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} items`,
                        pageSizeOptions: ['10', '20', '30'],
                      }"
                    >
                      <a-table-column key="title" data-index="title">
                        <span slot="title" style="">Name</span>
                      </a-table-column>
                      <a-table-column
                        key="all"
                        data-index="all"
                        :width="70"
                        align="center"
                      >
                        <span slot="title" style="">All</span>
                        <a-checkbox
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :checked="text == 1"
                          @change="
                            (e) => selectedCheckbox(e, record, index, column)
                          "
                        />
                      </a-table-column>
                      <a-table-column
                        key="access"
                        data-index="access"
                        :width="120"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          @click="selectAll('access', 'children', index)"
                          >Access Page</span
                        >
                        <a-checkbox
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :checked="text == 1"
                          @change="
                            (e) => selectedCheckbox(e, record, index, column)
                          "
                        />
                      </a-table-column>
                      <a-table-column
                        key="create"
                        data-index="create"
                        :width="70"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          @click="selectAll('create', 'children', index)"
                          >Create</span
                        >
                        <a-checkbox
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :checked="text == 1"
                          @change="
                            (e) => selectedCheckbox(e, record, index, column)
                          "
                        />
                      </a-table-column>
                      <a-table-column
                        key="read"
                        data-index="read"
                        :width="70"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          @click="selectAll('read', 'children', index)"
                          >Read</span
                        >
                        <a-checkbox
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :checked="text == 1"
                          @change="
                            (e) => selectedCheckbox(e, record, index, column)
                          "
                        />
                      </a-table-column>
                      <a-table-column
                        key="update"
                        data-index="update"
                        :width="70"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          @click="selectAll('update', 'children', index)"
                          >Update</span
                        >
                        <a-checkbox
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :checked="text == 1"
                          @change="
                            (e) => selectedCheckbox(e, record, index, column)
                          "
                        />
                      </a-table-column>
                      <a-table-column
                        key="delete"
                        data-index="delete"
                        :width="70"
                        align="center"
                      >
                        <span
                          slot="title"
                          style="color: #1890ff; cursor: pointer;"
                          @click="selectAll('delete', 'children', index)"
                          >Delete</span
                        >
                        <a-checkbox
                          :key="componentKey"
                          slot="customRender"
                          slot-scope="text, record, index, column"
                          :checked="text == 1"
                          @change="
                            (e) => selectedCheckbox(e, record, index, column)
                          "
                        />
                      </a-table-column>
                    </a-table>
                  </template> -->
                  <a-table-column key="title" data-index="title">
                    <span slot="title" style="">Name</span>
                    <template slot="customRender" slot-scope="text, record">
                      <strong v-if="record.children.length !== 0">{{ text }}</strong>
                      <label v-else>{{ text }}</label>
                    </template>
                  </a-table-column>
                  <a-table-column
                    key="all"
                    data-index="all"
                    :width="70"
                    align="center"
                  >
                    <span slot="title" style="">All</span>
                    <a-checkbox
                      :key="componentKey"
                      slot="customRender"
                      slot-scope="text, record, index, column"
                      :checked="text == 1"
                      @change="
                        (e) => selectedCheckbox(e, record, index, column)
                      "
                    />
                  </a-table-column>
                  <a-table-column
                    key="access"
                    data-index="access"
                    :width="120"
                    align="center"
                  >
                    <span
                      slot="title"
                      style="color: #1890ff; cursor: pointer;"
                      @click="selectAll('access')"
                      >Access Page</span
                    >
                    <a-checkbox
                      :key="componentKey"
                      slot="customRender"
                      slot-scope="text, record, index, column"
                      :checked="text == 1"
                      @change="
                        (e) => selectedCheckbox(e, record, index, column)
                      "
                    />
                  </a-table-column>
                  <a-table-column
                    key="create"
                    data-index="create"
                    :width="70"
                    align="center"
                  >
                    <span
                      slot="title"
                      style="color: #1890ff; cursor: pointer;"
                      @click="selectAll('create')"
                      >Create</span
                    >
                    <a-checkbox
                      :key="componentKey"
                      slot="customRender"
                      slot-scope="text, record, index, column"
                      :checked="text == 1"
                      @change="
                        (e) => selectedCheckbox(e, record, index, column)
                      "
                    />
                  </a-table-column>
                  <a-table-column
                    key="read"
                    data-index="read"
                    :width="70"
                    align="center"
                  >
                    <span
                      slot="title"
                      style="color: #1890ff; cursor: pointer;"
                      @click="selectAll('read')"
                      >Read</span
                    >
                    <a-checkbox
                      :key="componentKey"
                      slot="customRender"
                      slot-scope="text, record, index, column"
                      :checked="text == 1"
                      @change="
                        (e) => selectedCheckbox(e, record, index, column)
                      "
                    />
                  </a-table-column>
                  <a-table-column
                    key="update"
                    data-index="update"
                    :width="70"
                    align="center"
                  >
                    <span
                      slot="title"
                      style="color: #1890ff; cursor: pointer;"
                      @click="selectAll('update')"
                      >Update</span
                    >
                    <a-checkbox
                      :key="componentKey"
                      slot="customRender"
                      slot-scope="text, record, index, column"
                      :checked="text == 1"
                      @change="
                        (e) => selectedCheckbox(e, record, index, column)
                      "
                    />
                  </a-table-column>
                  <a-table-column
                    key="delete"
                    data-index="delete"
                    :width="70"
                    align="center"
                  >
                    <span
                      slot="title"
                      style="color: #1890ff; cursor: pointer;"
                      @click="selectAll('delete')"
                      >Delete</span
                    >
                    <a-checkbox
                      :key="componentKey"
                      slot="customRender"
                      slot-scope="text, record, index, column"
                      :checked="text == 1"
                      @change="
                        (e) => selectedCheckbox(e, record, index, column)
                      "
                    />
                  </a-table-column>
                  <!-- <template
                    slot="expandedRowRender"
                    slot-scope="record"
                    style="margin: 0;"
                  >
                    <a-table
                      :columns="childColumns"
                      :dataSource="record.list_permission"
                      size="small"
                      :pagination="{
                        hideOnSinglePage: true,
                        defaultPageSize: 10,
                        showQuickJumper: true,
                        showSizeChanger: true,
                        showTotal: (total) => `Total ${total} items`,
                        pageSizeOptions: ['10', '20', '30'],
                      }"
                    >
                    </a-table>
                  </template> -->
                  <a-checkbox
                    :key="componentKey"
                    slot="checkbox"
                    slot-scope="text, record, index, column"
                    :checked="text == 1"
                    @change="(e) => selectedCheckbox(e, record, index, column)"
                  />
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import { Modal } from 'ant-design-vue'

export default {
  data() {
    return {
      selectedidbpr: null,
      selectedidapp: null,
      role: null,
      databpr: [],
      dataapp: [],
      datarole: [],
      name: 'Permissions',
      changed: false,
      changedData: [],
      rolename: 'Loading...',
      columns: [
        {
          title: 'Name',
          dataIndex: 'title',
          scopedSlots: { customRender: 'title' },
        },
        {
          title: 'Access Page',
          dataIndex: 'access',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
          width: 120,
        },
        {
          title: 'Create',
          dataIndex: 'create',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
          width: 70,
        },
        {
          title: 'Read',
          dataIndex: 'read',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
          width: 70,
        },
        {
          title: 'Update',
          dataIndex: 'update',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
          width: 70,
        },
        {
          title: 'Delete',
          dataIndex: 'delete',
          scopedSlots: { customRender: 'checkbox' },
          align: 'center',
          width: 70,
        },
      ],
      table: 'permission',
      form: this.$form.createForm(this, { name: 'Form ' + this.table }),
      datatable: [],
      componentKey: 0,
    }
  },
  created() {
    this.getBpr()
  },
  methods: {
    resetAll() {
      this.role = null
      this.datatable = []
      this.getApp()
    },
    checkingIfNotNull() {
      if (this.selectedidbpr !== null && this.selectedidapp !== null && this.role !== null) {
        this.getData()
      }
    },
    selectedCheckbox(e, record, index, column) {
      record[column.dataIndex] = e.target.checked ? 1 : 0
      // console.log(column, record[column.dataIndex])
      var dataHasChildren = (datas) => { // Infinity if there's children
        datas.children.forEach((el) => {
          el.access = e.target.checked ? 1 : 0
          el.create = e.target.checked ? 1 : 0
          el.read = e.target.checked ? 1 : 0
          el.update = e.target.checked ? 1 : 0
          el.delete = e.target.checked ? 1 : 0
          if (el.children.length !== 0) { // Checking if children has children
            dataHasChildren(el) // Run self is there's children
          }
        })
      }
      if (column.dataIndex === 'all') {
        // var exist = this.checkAll(record)
        if (e.target.checked) {
          record.access = 1
          record.create = 1
          record.read = 1
          record.update = 1
          record.delete = 1
          if (record.children.length !== 0) {
            dataHasChildren(record) // First trigger magic if there's children selected(true)
          }
          var chdatat = this.$g.clone(record)
          // delete chdatat.children
          var tgdatat = this.changedData.findIndex(el => el.id_Permission === record.id_Permission)
          if (tgdatat >= 0) {
            this.changedData[tgdatat] = chdatat
          } else {
            this.changedData.push(chdatat)
          }
        } else {
          record.access = 0
          record.create = 0
          record.read = 0
          record.update = 0
          record.delete = 0
          if (record.children.length !== 0) {
            dataHasChildren(record) // First trigger magic if there's children selected(false)
          }
          var chdataf = this.$g.clone(record)
          // delete chdataf.children
          var tgdataf = this.changedData.findIndex(x => x.id_Permission === record.id_Permission)
          if (tgdataf >= 0) {
            this.changedData[tgdataf] = chdataf
          } else {
            this.changedData.push(chdataf)
          }
        }
      }
      if (record.children.length !== 0) {
        record.children.forEach((x) => {
          x[column.dataIndex] = e.target.checked
        })
      }
      // console.log('this.changedData', this.changedData)
      this.componentKey += 1
      this.changed = true
    },
    async getBpr() {
      var res = await lou.readMaster('manage/users/bprlist', false, true)
      if (res) {
        this.databpr = res.data
        // if (this.databpr !== null && this.databpr.length !== 0) {
        //   this.selectedidbpr = this.databpr[0].id_bpr
        // }
      }
    },
    async getApp() {
      var res = await lou.readMaster('manage/users/applist?id_bpr=' + this.selectedidbpr, false, true)
      var response = await lou.customUrlGet('manage/roles?id_bpr=' + this.selectedidbpr, false, true)
      if (res) {
        this.dataapp = res.data
        // if (this.dataapp !== null && this.dataapp.length !== 0) {
        //   this.selectedidapp = this.dataapp[0].id
        // }
      }
      if (response) {
        this.datarole = response.data
        // if (this.datarole !== null && this.datarole.length !== 0) {
        //   this.role = this.datarole[0].id
        //   this.rolename = this.datarole[0].name
        // }
      }
      this.checkingIfNotNull()
      // if (res && response) {
      //   this.getData()
      // }
    },
    async getRole() {
      this.getData()
    },
    checkAll(record) {
      var adakosong = false
      if (record.access === 0) {
        adakosong = true
      }
      if (record.create === 0) {
        adakosong = true
      }
      if (record.read === 0) {
        adakosong = true
      }
      if (record.update === 0) {
        adakosong = true
      }
      if (record.delete === 0) {
        adakosong = true
      }
      return adakosong
    },
    async saveChange() {
      // console.log('this.datatable', this.datatable)
      var yangdisimpan = []
      this.changedData.forEach((x) => {
        // if (x.children !== undefined && x.children.length !== 0) {
        // x.children.forEach((el) => {
        // yangdisimpan.push(el)
        // })
        // delete x.children
        // }
        yangdisimpan.push(x)
      })
      var fd = yangdisimpan
      // console.log('fd', JSON.stringify(fd))
      var response = await lou.customUrlPut('manage/permissions?id_bpr=' + this.selectedidbpr + '&AppID=' + this.selectedidapp + '&id_role=' + this.role, fd, false, true)
      // console.log('response', response)
      if (!response.isError) {
        // this.datatable = response.data
        // this.getData()
        this.datatable = response.data
        // console.log('res.data', res.data)
        this.datatable.all = 0
        this.changed = false
        lou.shownotif('Successfully', 'Permission telah terupdate!', 'success')
      }
    },
    async getData() {
      this.componentKey += 1
      var res = await lou.customUrlGet('manage/permissions?id_bpr=' + this.selectedidbpr + '&AppID=' + this.selectedidapp + '&id_role=' + this.role, false, true)
      this.datatable = res.data
      // console.log('res.data', res.data)
      this.datatable.all = 0
    },
    selectAll(column, from, index) {
      if (from === 'children') {
        var targetsubmenu = this.datatable[index].children.filter((x) => x[column] === 0)
        if (targetsubmenu.length === 0) {
          this.datatable[index].children.forEach((element) => {
            element[column] = targetsubmenu[column] === 0 ? 1 : 0
          })
        } else {
          this.datatable[index].children.forEach((element) => {
            element[column] = targetsubmenu[column] === 1 ? 0 : 1
          })
        }
      } else {
        if (column === 'all') {
          this.datatable.forEach((x) => {
            x.create = x.create === 0 ? 1 : 0
            x.read = x.read === 0 ? 1 : 0
            x.update = x.update === 0 ? 1 : 0
            x.delete = x.delete === 0 ? 1 : 0
          })
        } else {
          var target = this.datatable.filter((x) => x[column] === 0)
          if (target.length === 0) {
            this.datatable.forEach((element) => {
              element[column] = target[column] === 0 ? 1 : 0
            })
          } else {
            this.datatable.forEach((element) => {
              element[column] = target[column] === 1 ? 0 : 1
            })
          }
        }
      }
      this.changed = true
    },
    selectAllRow(column) {},
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.deleteMaster(this.table, deldata.id)
          this.data = response.data
          this.getData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    getnamerole() {
      var target = this.datarole.filter((x) => x.id === this.role)[0]
      this.rolename = target.name
      this.checkingIfNotNull()
    },
    clickcheckboxall() {
      this.$refs.checkboxall.click()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>
